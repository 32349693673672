<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-32">
      <h3 class="m-0">Документы для загрузки</h3>
    </div>
    <base-upload :required="true" id="scan_trip_education" name="scan_trip_education" title="Направление на обучение в АНО ДПО «Школа экспорта АО «Российский экспортный центр»"
                 description="<span class='text-red'>Для оформления заявки на дистанционное обучение необходимо подготовить и приложить направление на обучение, оформленное на бланке Вашей организации (с печатью организации и подписью руководителя). Образец направления на обучение можно скачать ниже</span>"
                 v-model="docs.scan_trip_education" :sample="{title: 'Образец направления на обучение', link: 'https://exporteduru.servicecdn.ru/media/attachment/0002/55/2f8595a322ebeda240ccf01fbf3f040d2abcfb5d.docx'}" />
    <template v-if="hasFull">
      <base-upload :required="true" id="scan_passport" name="scan_passport" title="Скан паспорта" v-model="docs.scan_passport"/>
      <base-upload :required="true" id="scan_diploma" name="scan_diploma" title="Скан диплома о высшем образовании" v-model="docs.scan_diploma"/>
      <base-upload :required="true" id="scan_snils" name="scan_snils" title="Скан СНИЛС" v-model="docs.scan_snils"/>
    </template>
  </div>
</template>

<script>
import BaseUpload from "@/components/form/BaseUpload.vue";
import ApiService from "../../services/api.service";

export default {
  name: "OnlineOrderDocs",
  components: {BaseUpload},
  props: ['value', 'hasFull'],
  async mounted() {
    this.docs = (await ApiService.get('userdoc/extramural')).data
    this.firstLoad = false
  },
  methods: {
    getMedia(val, field) {
      return val[field] ? val[field].id : null
    }
  },
  data() {
    return {
      firstLoad: true,
      docs: {
        scan_trip_education: null
      }
    }
  },
  watch: {
    docs: {
      handler: function(val) {
        if(val.id && !this.firstLoad) {
          this.$emit('input', val)
          ApiService.put('userdocs/' + val.id, {
            scan_trip_education: this.getMedia(val, 'scan_trip_education'),
            scan_passport: this.getMedia(val, 'scan_passport'),
            scan_diploma: this.getMedia(val, 'scan_diploma'),
            scan_snils: this.getMedia(val, 'scan_snils'),
          })
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import "./src/assets/style/variables";
.docs-needed {
  color: $blue-03;
  text-decoration: underline;
  position: relative;
  padding-right: 3.4rem;
  &:hover {
    text-decoration: none;
  }
  &:after {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    right: 0;
    content: "";
    top: 0;
    background: url("/pic/profile/arrow-up-right.svg");
  }
}
</style>