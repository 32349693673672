<template>
  <div class="section__lk-order-form container-shadow fill-white">
    <template v-if="participant">
      <div class="d-flex justify-content-between">
        <h2>Данные заявки</h2>
        <router-link to="/lk" class="test__footer-link d-none d-lg-block">
          Вернуться в личный кабинет
          <img src="/pic/icon-arrow-circle-right.svg" />
        </router-link>
        <router-link to="/lk" class="test__footer-link d-sm-block d-md-none">
          Вернуться в кабинет
          <img src="/pic/icon-arrow-circle-right.svg" />
        </router-link>
      </div>
      <div class="separator"></div>
      <div v-if="participant.status === 'participant'">
        <h1>Ваша заявка на участие в дистанционном обучении отправлена на рассмотрение.</h1>
        <h3 class="mt-2">Как только она будет принята, вам станут доступны все курсы по программе онлайн обучения.</h3>
      </div>
      <div v-else-if="participant.status === 'approved'">
        <h1>Ваша заявка одобрена, вы участвуете в программе!</h1>
        <h3 class="mt-2">Вам доступна вкладка <router-link to="/lk/online">"Онлайн программа"</router-link> в <router-link to="/lk/online">Личном кабинете</router-link>.</h3>
      </div>
      <form v-else @submit.prevent="submit" novalidate>
        <div v-if="['rejected', 'pending'].indexOf(participant.status) > -1 && participant.program_participants.length">
          <h3>Комментарии от администратора</h3>
          <admin-comments :comments="participant.program_participants" />
          <div class="separator"></div>
        </div>
        <div class="pt-2">
          <user-settings :show-address="false" :show-snils="hasFull" v-model="user" />
        </div>
        <template v-if="hasFull">
          <div class="separator"></div>
          <div class="pt-2">
            <education-settings v-model="education" />
          </div>
          <div class="separator"></div>
          <div class="pt-2">
            <passport-settings v-model="passport" />
          </div>
        </template>
        <div class="separator"></div>
        <company-settings v-model="organization" />
        <div class="separator"></div>
        <div class="pt-2">
          <online-order-docs :has-full="hasFull" v-model="userDocs" />
        </div>
        <div class="pt-2" v-if="participant.status === 'pending' && participant.contract">
          <div class="separator"></div>
          <online-contract :link="participant.contract.file" v-model="contractTemplate" />
        </div>
        <div class="section__lk-order-actions">
          <div><button type="submit" class="link-target"><span>продолжить</span></button></div>
          <div class="section__lk-order-draft"><a class="" href="#" @click.prevent="draft()">Сохранить в черновик</a></div>
          <a target="_blank" href="mailto:info@exportedu.ru" class="btn btn-outline-primary section__lk-order-help"><span>Нужна помощь?</span></a>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import UserSettings from "@/components/profile/UserSettings.vue";
import {TokenService} from "../../services/token.service";
import CompanySettings from "@/components/CompanySettings.vue";
import ApiService from "../../services/api.service";
import OnlineOrderDocs from "@/components/OnlineOrderDocs.vue";
import {eventBus} from "@/main";
import AdminComments from "@/components/AdminComments.vue";
import EducationSettings from "@/components/EducationSettings.vue";
import PassportSettings from "@/components/PassportSettings.vue";
import OnlineContract from "@/components/OnlineContract.vue";

const TOKEN = '4jksuebc53'

export default {
  name: "OnlineOrderForm",
  components: {
    OnlineContract,
    PassportSettings, EducationSettings, AdminComments, OnlineOrderDocs, CompanySettings, UserSettings},
  data() {
    return {
      user: TokenService.getUserObj(),
      order: {},
      hasFull: false,
      passport: null,
      education: TokenService.getUserObj(),
      userDocs: {},
      organization: null,
      participant: null,
      contractTemplate: null
    }
  },
  async mounted() {
    if(this.$route.query.token === TOKEN) {
      this.hasFull = true
    }
    this.participant = (await ApiService.get('programparticipant')).data
    if(!this.participant) {
      this.participant = (await ApiService.post('programparticipants', {is_draft: true})).data
    }

    if(!this.hasFull && this.participant.is_onec) {
      this.hasFull = true
    }

  },
  methods: {
    async submit() {
      let result = await this.$validator.validateAll()
      let formRes = await this.saveForm()
      if(result && formRes && this.validDocs && this.validContract) {
        let res = await ApiService.post('programparticipants', {
          onec: this.hasFull ? true : null
        })

        this.participant = res.data
        if(res.status === 200) {
          this.$toastr.s('', "Заявка успешно отправлена");
          await this.$router.push('/lk/requests').catch(() => {})
        } else {
          this.$toastr.e('', "Проверьте правильность заполнения полей");
        }
      } else {
        this.$toastr.e('', "Проверьте правильность заполнения полей");
      }
    },
    async draft() {
      let res = await ApiService.post('programparticipants', {
        is_draft: true
      })
      this.participant = res.data
      await this.saveForm()
      if(res.status === 200) {
        this.$toastr.s('', "Черновик успешно сохранен");
      } else {
        this.$toastr.e('', "Проверьте правильность полей");
      }
    },
    async saveForm() {
      try {
        if(this.passport) {
          this.passport = (await ApiService.put('userpassport', {
            ...this.passport
          })).data
        }
        delete this.education.sex
        let userRes = await ApiService.put('user', {
          ...this.user,
          ...this.education,
          region: this.user.region.id,
          institute_country: 1
        })

        if(userRes.status < 300) {
          this.user = userRes.data
        }

        if(this.organization) {
          this.organization = (await ApiService.put('userorganization', {
            ...this.organization,
            contractTemplate: this.contractTemplate ? this.contractTemplate.id : null
          })).data
        }

        TokenService.setUser(this.user);
        eventBus.$emit('user:upd');
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
  },
  watch: {
    organization: {
      handler(val) {
        if(val.contract_template) {
          this.contractTemplate = val.contract_template
        }
      },
      deep: true
    }
  },
  computed: {
    validDocs() {
      if(this.hasFull) {
        return this.userDocs.scan_trip_education && this.userDocs.scan_diploma && this.userDocs.scan_passport && this.userDocs.scan_snils
      }
      return this.userDocs.scan_trip_education
    },
    validContract() {
      if (this.participant.status === 'pending') {
        return this.organization && this.organization.contract_template
      } else {
        return true;
      }
    },
  }
}
</script>

<style scoped>

</style>