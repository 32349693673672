<template>
  <div class="section section__lk">
    <div class="section__wrapper section__subsection-wrapper">
      <div class="d-flex justify-content-between">
        <div class="title-with-icon mb-48">
          <div class="title-with-icon__icon title-with-icon__icon_order"></div>
          <div class="title-with-icon__title">Заявка на онлайн программу</div>
        </div>
      </div>
      <div class="section__lk-wrapper">
        <div class="section__lk-order-info container-shadow fill-white mr-24 p-24 d-none d-md-block">
          <div class="section__lk-order-info__security">
            <p>
              Заполнение полей необходимо для оформления заявки на дистанционное обучение.
              После заполнения полей Ваши данные отправятся на проверку администраторам для одобрения Вашего участия.
              Школа экспорта обеспечивает конфиденциальность персональных данных заявителя.</p>
            <ul>
              <li>возможность бесплатно пройти обучение по всем курсам Школы Экспорта РЭЦ</li>
              <li>доступ ко всем обучающим материалам в течение года с момента одобрения заявки</li>
            </ul>
          </div>
        </div>
        <online-order-form />
      </div>
      <div class="section__lk-order-info__security d-block d-md-none container-shadow fill-white mt-24 p-24">
        <p><a href="#" class="content_red">Заполнение полей</a> необходимо для проверки Ваших данных
          администраторами<sup>1</sup> для одобрения Вашего
          участия. Школа экспорта обеспечивает конфиденциальность персональных данных заявителя.</p>
        <p>Заключение договора откроет для вас следующие возможности:</p>
        <ul>
          <li>возможность бесплатно пройти обучение по всем курсам Образовательной программы РЭЦ</li>
          <li>возможность получить удостоверение о повышении квалификации по программе «Старт экспортной деятельности: 10 шагов к успеху»</li>
          <li>доступ ко всем обучающим материалам в течение года с момента заключения договора на обучение</li>
        </ul>
        <p class="section__lk-order-info__notice content_14"><sup>1</sup> Приказ Министерства образования и науки
          Российской Федерации от
          01.07.2013 №499 “Об утверждении Порядка организации и осуществления образовательной деятельности по
          дополнительным профессиональным программам”.</p>
      </div>
    </div>
  </div>
</template>

<script>
import OnlineOrderForm from "@/components/OnlineOrderForm.vue";

export default {
  name: "OnlineOrder",
  components: {OnlineOrderForm},
  mounted() {
    this.$parent.init()
  }
}
</script>

<style scoped lang="scss">
.section__lk-order-info {
  max-width: 30%;
}
.section__lk-order-info__security {
  font-size: 1.8rem;
  line-height: 3.2rem;
  p, ul li {
    line-height: 3.2rem;
  }
  li::marker {
    font-size: .5em;
  }
  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 32px;
    p, ul li {
      line-height: 32px;
      font-size: 18px;
    }
    li::marker {
      font-size: 5px;
    }
  }
}
</style>