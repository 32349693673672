<template>
  <div class="row">
    <div class="mb-32 col-sm-12">
      <h3 class="m-0">Договор</h3>
    </div>
    <div class="col-md-6">
      <document :css-class="'fill-grey'" title="Договор" :link="link" />
    </div>
    <div class="col-md-6">
      <base-upload :required="true" :contract-theme="true" id="contract" name="contract" title="Загрузить подписанный договор" v-model="model"/>
    </div>
  </div>
</template>

<script>
import Document from "@/components/Document.vue";
import BaseUpload from "@/components/form/BaseUpload.vue";
import VmMixin from "@/mixins/vmMixin";

export default {
  name: "OnlineContract",
  components: {BaseUpload, Document},
  mixins: [VmMixin],
  props: {
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>